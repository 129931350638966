import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import { styled } from '@activebrands/core-web/libs/styletron';
import Heading from 'components/text/Heading';
import Cookies from 'js-cookie';

const Wrapper = styled('div');

const List = styled('ul', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    ...textStyles['Primary/16_120_-1'],
});

const SearchHistoryListing = ({ $style = {} }) => {
    const items = Cookies.get('searchHistory');

    if (!items) {
        return null;
    }

    const parsedItems = JSON.parse(items);

    return (
        <Wrapper $style={$style}>
            <Heading
                $style={{ marginBottom: '12px', color: 'var(--color-text-subtle)' }}
                as="h4"
                fontKeys="Miscellaneous/12_100"
            >
                {fm('Search history')}
            </Heading>
            <List>
                {parsedItems.map(item => (
                    <li key={item}>{item}</li>
                ))}
            </List>
        </Wrapper>
    );
};

SearchHistoryListing.propTypes = {
    $style: PropTypes.object,
};

export default SearchHistoryListing;
